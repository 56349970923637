<template>
  <r-container class="visits-page">
    <r-infinite-page :url="'user/visits/me'">
      <template v-slot="props">
        <r-card class="pa-2 my-3" v-for="(visit, i) in props.items" :key="i">
          <r-row>
            <r-col class="col-12 md-6 v-center">
              <r-img
                :src="
                  '/' +
                  $helper.ifHas(
                    visit,
                    'storage/site/avatar.jpg',
                    'user',
                    0,
                    'images',
                    0
                  )
                "
                width="50"
                height="50"
                :alt="visit.user[0].name"
              ></r-img>
              <span class="px-2"
                >{{ visit.user[0].name }} {{ visit.user[0].family }}</span
              >
              <span class="px-2">{{ visit.doctor[0].user.name }}</span>
              <span class="px-2">{{ $d(new Date(visit.date), "short") }}</span>
            </r-col>
            <r-col class="col-12 md-6 v-center">
              <span
                ><r-chip>{{ visit.time }}</r-chip></span
              >
              <r-spacer></r-spacer>
              <span
                class="px-2 py-1 br-md mx-1"
                :class="{
                  'color-black color-white-text': visit.status === 0,
                  'color-info': visit.status === 1,
                  'color-success': visit.status === 2,
                }"
                >{{ status(visit.status) }}</span
              >
              <r-btn class="mx-1" icon @click="start(visit._id)">
                <r-icon
                  viewBox="0 0 48 48"
                  v-html="
                    '<path fill=\'#4CAF50\' d=\'M8 12h22c2.2 0 4 1.8 4 4v16c0 2.2-1.8 4-4 4H8c-2.2 0-4-1.8-4-4V16c0-2.2 1.8-4 4-4z\'/><path fill=\'#388E3C\' d=\'m44 35l-10-6V19l10-6z\'/>'
                  "
                ></r-icon>
              </r-btn>
            </r-col>
          </r-row>
        </r-card>
      </template>
    </r-infinite-page>

    <r-modal v-model="show" :closebtn="false" maxWidth="300px">
      <r-card class="pb-2">
        <r-call
          ref="call"
          :room-id="roomId"
          :ws-url="url"
          :width="300"
          :height="520"
          @stop-chat="show = false"
          recorded
        ></r-call>
      </r-card>
    </r-modal>
  </r-container>
</template>

<script>
export default {
  name: "visists",
  data() {
    return {
      show: false,
      roomId: null,
      selected: 0,
      visits: [],
      url: `${
        process.env.NODE_ENV === "production"
          ? process.env.VUE_APP_WS_production_URL
          : process.env.VUE_APP_WS_URL
      }/live`,
    };
  },
  methods: {
    status(n) {
      if (n === 0) {
        return this.$t("not_paid");
      }
      if (n === 1) {
        return this.$t("waiting");
      }
      if (n === 2) {
        return this.$t("examined");
      }
    },
    start(id) {
      this.roomId = id;
      this.show = true;
    },
  },
};
</script>

<style lang="scss">
@import "~renusify/style/include";
.select-field {
  width: 125px;
}
</style>
